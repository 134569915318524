import React from 'react';
import Layout from '../common/layouts';
import {Link} from 'gatsby';
import axios from 'axios';
import Seo from '../common/seo';


export const LibroPortada = ({libro}) => {
    return(
      <Link to={"/book?bookID="+libro.id+"&bookTitle="+libro.title}>
        <div className="rounded-lg shadow-lg hover:opacity-60 transition duration-300 ease-in-out hover:bg-blue-400"
      // onClick={
      //   () => window.open(libro.pdfURL)}
      >
        <h2>{libro.title}</h2>
        {/*<img className="w-full h-56"*/}
        {/*     src={post.imageURL}*/}
        {/*     objectFit="cover"*/}
        {/*     objectPosition="50% 50%"*/}
        {/*/>*/}
        {/*<div className="px-6 py-4">*/}
        {/*  <h4 className="mb-3 text-xl font-semibold tracking-tight text-gray-800">{post.title}</h4>*/}
        {/*  <p className="leading-normal text-gray-700"*/}
        {/*     dangerouslySetInnerHTML={{*/}
        {/*       __html: post.excerpt*/}
        {/*     }} />*/}
        {/*</div>*/}
      </div>
      </Link>
    )
}


const Libros = () => {
  const [books, setBooks] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);

  if (!loaded) {
    axios({
      method: 'post',
      url: 'https://us-central1-akusmatika-31aec.cloudfunctions.net/api/v1/getBooks',
    }).then((res) => {
        setBooks(res.data);
        setLoaded(true);
      });
  }

  return (
    <Layout>
     {/*<div className="container mx-auto">*/}
        <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-16 pl-16 pr-16 pt-16 pb-8">

          {books.map( (book)  =>
            <LibroPortada libro={book}/>
          )}
        </div>
       {/*</div>*/}
    </Layout>
  )
}




export default Libros
